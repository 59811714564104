<!--销售明细表-->
<template>
    <div class="sales-detail-report">
        <reportTable
            code="SalePriceDetails"
            :query="query"
            @generateComplete="generateComplete"
            @exportTable="exportTable"
            :percentage="percentage"
            custom-export
        >
            <template #queryItems>
                <div class="query-item">
                    <label class="name">场站：</label>
                    <el-select
                        v-model="query.station_id"
                        placeholder="请选择"
                        clearable
                    >
                        <el-option
                            v-for="item in stationList"
                            :key="item.station_id"
                            :label="item.name"
                            :value="item.station_id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="query-item">
                    <label class="name">合同类型：</label>
                    <el-select
                        v-model="query.contract_type"
                        placeholder="请选择"
                        clearable
                    >
                        <el-option
                            v-for="item in contractTypes"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="query-item">
                    <label class="name">合同编号：</label>
                    <el-input placeholder="请输入" v-model="query.contract_number"></el-input>
                </div>
                <div class="query-item">
                    <label class="name">客户名称：</label>
                    <el-input placeholder="请输入" v-model="query.client_name"></el-input>
                </div>
                <div class="query-item">
                    <label class="name">业务经理：</label>
                    <el-input placeholder="请输入" v-model="query.salesman_name"></el-input>
                </div>
            </template>
            <template #customTable>
                <div
                    class="table-box"
                    id="download-excel"
                    v-loading="loading"
                    style="height: 100%"
                >
                    <div class="table-head">
                        <table class="t-table" cellpadding="0" cellspacing="0">
                            <thead>
                                <tr>
                                    <th style="width: 0.8rem" rowspan="2">
                                        销售经理
                                    </th>
                                    <th style="width: 1.5rem" rowspan="2">
                                        合同编号
                                    </th>
                                    <th style="width: 2rem" rowspan="2">
                                        客户名称
                                    </th>
                                    <th style="width: 2rem" rowspan="2">
                                        工程名称
                                    </th>
                                    <th style="width: 1.1rem" rowspan="2">
                                        合同类型
                                    </th>
                                    <th style="width: 0.8rem" rowspan="2">
                                        工程类型
                                    </th>
                                    <th style="width: 1.5rem" rowspan="2">
                                        型号
                                    </th>
                                    <th
                                        v-for="name in tableStationList"
                                        class="td-box"
                                        colspan="11"
                                        :key="name+'col'"
                                    >
                                        {{ name }}
                                    </th>
                                    <th colspan="4">
                                        合计
                                    </th>
                                </tr>
                                <tr>
                                    <template v-for="(name,index) in tableStationList" class="td-box" colspan="11">
                                        <th style="width: 0.8rem" :key="name+index+'a'">
                                            方量
                                        </th>
                                        <th style="width: 0.8rem" :key="name+index+'b'">
                                            单价
                                        </th>
                                        <th style="width: 0.8rem" :key="name+index+'c'">
                                            砼款
                                        </th>
                                        <th style="width: 0.8rem" :key="name+index+'d'">
                                            泵送
                                        </th>
                                        <th style="width: 0.8rem" :key="name+index+'e'">
                                            泵送单价
                                        </th>
                                        <th style="width: 0.8rem" :key="name+index+'f'">
                                            泵送金额
                                        </th>
                                        <th style="width: 0.8rem" :key="name+index+'g'">
                                            趟数
                                        </th>
                                        <th style="width: 0.8rem" :key="name+index+'h'">
                                            运费
                                        </th>
                                        <th style="width: 1rem" :key="name+index+'i'">
                                            运费补方数
                                        </th>
                                        <th style="width: 1.2rem" :key="name+index+'j'">
                                            运费补方金额
                                        </th>
                                        <th style="width: 1rem" :key="name+index+'k'">
                                            小计
                                        </th>
                                    </template>
                                    <th style="width: 0.8rem">
                                        方量
                                    </th>
                                    <th style="width: 0.8rem">
                                        泵送
                                    </th>
                                    <th style="width: 0.8rem">
                                        运费补方
                                    </th>
                                    <th style="width: 0.8rem">
                                        总金额
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="table-body">
                        <table
                            class="t-table"
                            v-if="tableList.length>0"
                            cellpadding="0"
                            cellspacing="0"
                        >
                            <tbody>
                                <template v-for="(item1,index1) in tableList">
                                    <tr :key="index1">
                                        <td
                                            v-if="mergeCells['salesman_name'+index1]!=0"
                                            :rowspan="mergeCells['salesman_name'+index1]"
                                            style="width: 0.8rem"
                                        >
                                            {{ item1.salesman_name }}
                                        </td>
                                        <td
                                            v-if="mergeCells['contract_number'+index1]!=0"
                                            :rowspan="mergeCells['contract_number'+index1]"
                                            style="width: 1.5rem"
                                        >
                                            {{ item1.contract_number }}
                                        </td>
                                        <td
                                            v-if="mergeCells['client_name'+index1]!=0"
                                            :rowspan="mergeCells['client_name'+index1]"
                                            style="width: 2rem"
                                        >
                                            {{ item1.client_name }}
                                        </td>
                                        <td
                                            v-if="mergeCells['engineering_name'+index1]!=0"
                                            :rowspan="mergeCells['engineering_name'+index1]"
                                            style="width: 2rem"
                                        >
                                            {{ item1.engineering_name }}
                                        </td>
                                        <td
                                            v-if="mergeCells['contract_type_name'+index1]!=0"
                                            :rowspan="mergeCells['contract_type_name'+index1]"
                                            style="width: 1.1rem"
                                        >
                                            {{ item1.contract_type_name }}
                                        </td>
                                        <td
                                            v-if="mergeCells['engineering_type_name'+index1]!=0"
                                            :rowspan="mergeCells['engineering_type_name'+index1]"
                                            style="width: 0.8rem"
                                        >
                                            {{ item1.engineering_type_name }}
                                        </td>
                                        <td style="width: 1.5rem">
                                            {{ item1.cpbh }}
                                        </td>
                                        <template v-for="(item2,index2) in item1.list">
                                            <td style="width: 0.8rem" :key="index1+'-'+index2">
                                                {{ item2.sale_volume }}
                                            </td>
                                            <td style="width: 0.8rem" :key="index1+'adjusted_concrete_price'+index2">
                                                {{ item2.adjusted_concrete_price }}
                                            </td>
                                            <td style="width: 0.8rem" :key="index1+'concrete_money'+index2">
                                                {{ item2.concrete_money }}
                                            </td>
                                            <td style="width: 0.8rem" :key="index1+'adjusted_pump_volume'+index2">
                                                {{ item2.adjusted_pump_volume }}
                                            </td>
                                            <td style="width: 0.8rem" :key="index1+'pump_price'+index2">
                                                {{ item2.pump_price }}
                                            </td>
                                            <td style="width: 0.8rem" :key="index1+'adjusted_pump_money'+index2">
                                                {{ item2.adjusted_pump_money }}
                                            </td>
                                            <td style="width: 0.8rem" :key="index1+'counts'+index2">
                                                {{ item2.counts }}
                                            </td>
                                            <td style="width: 0.8rem" :key="index1+'adjusted_freight'+index2">
                                                {{ item2.adjusted_freight }}
                                            </td>
                                            <td style="width: 1rem" :key="index1+'replenish_quantity'+index2">
                                                {{ item2.replenish_quantity }}
                                            </td>
                                            <td style="width: 1.2rem" :key="index1+'replenish_money'+index2">
                                                {{ item2.replenish_money }}
                                            </td>
                                            <td style="width: 1rem" :key="index1+'adjusted_subtotal'+index2">
                                                {{ item2.adjusted_subtotal }}
                                            </td>
                                        </template>
                                        <td style="width: 0.8rem">
                                            {{ item1.sale_volume }}
                                        </td>
                                        <td style="width: 0.8rem">
                                            {{ item1.adjusted_pump_volume }}
                                        </td>
                                        <td style="width: 0.8rem">
                                            {{ item1.replenish_quantity }}
                                        </td>
                                        <td style="width: 0.8rem">
                                            {{ item1.adjusted_subtotal }}
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                        <div class="el-table__empty-text" v-if="tableList.length == 0"></div>
                    </div>
                </div>
            </template>
        </reportTable>
    </div>
</template>

<script>
import reportTable from '@/components/reportTable/reportTable.vue';
import qs from 'qs';
import OMS from '@/assets/js/exportExcel/exportTools';
export default {
    components: { reportTable },
    data() {
        return {
            loading: false,
            query: {
                contract_number: '',
                salesman_name: '',
                contract_type: '',
                station_id: '',
                client_name: '',
            },
            stationList: [],
            contractTypes: [],
            tableTitle: '销售明细表',
            tableList: [],
            tableStationList: [],
            // 分批加载 大小
            limit: 1,
            // 进度条
            percentage: 100,
            // 合并单元格配置
            mergeCells: {},
        };
    },
    created() {
        this.getStationList();
        this.getContractTypes();
    },
    methods: {
        // 获取场站列表
        async getStationList() {
            const data = await this.$axios.get('/interfaceApi/baseinfo/stationmanger/get_station_list');
            if (data.length > 0) {
                this.stationList = data;
            }
        },
        // 获取场站列表
        async getContractTypes() {
            const data = await this.$axios.get('/interfaceApi/baseinfo/dictionary_config/getlist?parent_code=102&type=1');
            if (data.length > 0) {
                this.contractTypes = data;
            }
        },
        // 报表生成完成
        async generateComplete(params) {
            try {
                this.loading = true;
                this.tableList = [];
                this.tableStationList = [];
                this.mergeCells = {};
                const clientUrl = `/interfaceApi/report/sale_price_details/salesman_ids?${qs.stringify(params)}`;
                const clientIds = await this.$axios.get(clientUrl);
                if (clientIds.length === 0) {
                    return;
                }
                this.percentage = 0;
                const arr = this.sliceArr(clientIds, this.limit);
                for (const i in arr) {
                    if (this.queryArr !== arr) {
                        return;
                    }
                    const listUrl = `/interfaceApi/report/sale_price_details/data_list?${qs.stringify(params)}`;
                    const list = await this.$axios.post(listUrl, arr[i]);
                    this.percentage = parseInt((Number(i) + 1) / arr.length * 100, 0);
                    const pushList = [];
                    list.forEach(item => {
                        pushList.push(...item);
                    });
                    if (list.length > 0) {
                        this.tableList.push(...pushList);
                        this.mergeCellsFn(this.tableList);
                    }
                    if (Number(i) === 0) {
                        this.loading = false;
                        if ( this.tableList.length > 0 ) {
                            this.tableStationList = this.tableList[0].list.map(item => item.station_name);
                        }
                    }
                }
                this.percentage = 100;
            } catch (e) {
                this.$message.error(e.ErrorCode ? e.ErrorCode.Message : e.message);
            } finally {
                this.percentage = 100;
                this.loading = false;
            }
        },
        sliceArr(arr, chunkSize) {
            const res = [];
            for (let i = 0; i < arr.length; i += chunkSize) {
                const chunk = arr.slice(i, i + chunkSize);
                res.push(chunk);
            }
            return res;
        },
        // 合并单元格方法
        mergeCellsFn(list = []) {
            const mergeProps = ['salesman_name', 'contract_number', 'client_name', 'engineering_name', 'contract_type_name', 'engineering_type_name'];
            list.forEach((item, index) => {
                for (const i in mergeProps) {
                    const prop = mergeProps[i];
                    const prevProp = mergeProps[i - 1];
                    const mergeKey = prop + '' + index;
                    if (this.mergeCells[mergeKey] || !item[prop]) {
                        continue;
                    }
                    let rowspan = 1;
                    let nextIndex = index + 1;
                    // eslint-disable-next-line max-len
                    while (list[nextIndex] && item[prop] === list[nextIndex][prop] && item[prevProp] === list[nextIndex]?.[prevProp] ) {
                        // 合并条件需要前面所有列也相同
                        let pass = true;
                        for (let m = 0; m < i; m++) {
                            const po = mergeProps[m];
                            if (item[po] !== list[nextIndex]?.[po]) {
                                pass = false;
                                break;
                            }
                        }
                        if (!pass) {
                            break;
                        }
                        rowspan++;
                        nextIndex++;
                    }
                    this.mergeCells[mergeKey] = rowspan;
                    let prevIndex = index - 1;
                    // eslint-disable-next-line max-len
                    while (list[prevIndex] && item[prop] === list[prevIndex][prop] && item[prevProp] === list[prevIndex]?.[prevProp] ) {
                        // 合并条件需要前面所有列也相同
                        let pass = true;
                        for (let m = 0; m < i; m++) {
                            const po = mergeProps[m];
                            if (item[po] !== list[prevIndex]?.[po]) {
                                pass = false;
                                break;
                            }
                        }
                        if (!pass) {
                            break;
                        }
                        rowspan++;
                        prevIndex--;
                        this.mergeCells[mergeKey] = 0;
                        break;
                    }

                }
            });
        },
        exportTable() {
            OMS.downLoadXlsx({
                dom: 'download-excel',
                name: this.tableTitle,
                ColumnWdth: [80, 150, 200, 200],
            });
        },
    },
    destroyed() {
        this.queryArr = null;
    },
};
</script>
<style lang="stylus" scoped>

</style>

